export const environment = {
  production: false,
  api: 'https://ener-back-7dedbe1abde8.herokuapp.com',
  //api: 'http://localhost:8080',
  clientId: 'energy',
  clientSecret: '@dev_ken130483',
  obterTokenUrl: '/oauth/token'
  //https://kentec-energy.run-us-west2.goorm.io
};


