<nav class="sb-topnav navbar navbar-expand navbar-dark bg-dark">
  <img src="assets/eg.jpg" id="logo" style="height: 52px; width: 52px; margin-left: -10px; border-radius: 100%;" alt="energy"/>
  <a class="navbar-brand" href="#"><span style="color:red; font-weight: bold;">ENER</span><span style="color: black; font-weight: bold;">GY</span></a>
    <a type="button" class="btn btn-link btn-sm order-1 order-lg-0" id="sidebarToggle" href="#">
      <i class="fas fa-bars"></i>
    </a>
</nav>
<div id="layoutSidenav">
  <div *ngIf="!mostrarMenu" class="col-md-1" style="margin-left: -90px;"></div>
  <div *ngIf="mostrarMenu" id="layoutSidenav_nav">
      <nav class="sb-sidenav accordion sb-sidenav-dark" id="sidenavAccordion">
          <div class="sb-sidenav-menu">
              <div class="nav">
                  <div class="sb-sidenav-menu-heading">Ínicio</div>
                  <a class="nav-link" href="index.html"><div class="sb-nav-link-icon"><i class="fas fa-tachometer-alt"></i>
                    </div>Home</a>
                  <div class="sb-sidenav-menu-heading">Menu</div>
                  <!--Modulo Aluno-->
                  <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseLayoutsalu" aria-expanded="false" aria-controls="collapseLayouts">
                    <div class="sb-nav-link-icon"><i class="fas fa-user-check"></i></div>
                      Alunos
                    <div class="sb-sidenav-collapse-arrow"><i class="fas fa-angle-down"></i></div>
                  </a>
                  <div class="collapse" id="collapseLayoutsalu" aria-labelledby="headingOne" data-parent="#sidenavAccordion">
                      <nav class="sb-sidenav-menu-nested nav">
                        <a class="nav-link" routerLink="/frequencia"><i class="fas fa-check-circle"></i>&nbsp;Frequência</a>
                        <a class="nav-link" routerLink="/frequencia/listar"><i class="fas fa-user-check"></i>&nbsp;Listar de Frequência</a>
                        <a class="nav-link" routerLink="/relatorioexercicios/listarexercicios"><i class="fas fa-dumbbell"></i>&nbsp;Lista de Exercícios</a>
                        <a class="nav-link" routerLink="/recibopagamento/recibo"><i class="fas fa-clipboard-check"></i>&nbsp;Recibo</a>
                      </nav>
                  </div>
                  <!--Modulo cadastro-->
                  <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseLayoutscad" aria-expanded="false" aria-controls="collapseLayouts">
                    <div class="sb-nav-link-icon"><i class="fas fa-cogs"></i></div>
                      Cadastros
                    <div class="sb-sidenav-collapse-arrow"><i class="fas fa-angle-down"></i></div>
                  </a>
                  <div class="collapse" id="collapseLayoutscad" aria-labelledby="headingOne" data-parent="#sidenavAccordion">
                      <nav class="sb-sidenav-menu-nested nav">
                        <a class="nav-link" routerLink="/cadastro"><i class="fas fa-user-edit"></i>&nbsp;Pessoas</a>
                        <a  class="nav-link" routerLink="/exercicio"><i class="fas fa-edit"></i>&nbsp;Exercícios</a>
                        <a  class="nav-link" routerLink="/fichatecnica"><i class="fas fa-list-alt"></i>&nbsp;Ficha técnica</a>
                        <a  class="nav-link" routerLink="/senha"><i class="fas fa-key"></i>&nbsp;Senhas</a>
                      </nav>
                  </div>
                  <!--Modulo Financeiro-->
                  <a  class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseLayoutsfin" aria-expanded="false" aria-controls="collapseLayouts">
                    <div class="sb-nav-link-icon"><i class="fas fa-donate"></i></div>
                      Financeiro
                    <div class="sb-sidenav-collapse-arrow"><i class="fas fa-angle-down"></i></div>
                  </a>
                  <div class="collapse" id="collapseLayoutsfin" aria-labelledby="headingOne" data-parent="#sidenavAccordion">
                      <nav class="sb-sidenav-menu-nested nav">
                        <a  class="nav-link" routerLink="/livrocaixaentrada"><i class="far fa-money-bill-alt"></i>&nbsp;Livro Caixa Entrada</a>
                        <a  class="nav-link" routerLink="/livrocaixaentrada/lista"><i class="fas fa-list-ol"></i>&nbsp;Listar Caixa Entrada</a>
                        <a  class="nav-link" routerLink="/fichafinanceira"><i class="fas fa-hand-holding-usd"></i>&nbsp;Mensalidade</a>
                      </nav>
                  </div>
                  <!--Modulo Consultas-->
                  <a  class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseLayoutscons" aria-expanded="false" aria-controls="collapseLayouts">
                    <div class="sb-nav-link-icon"><i class="fas fa-search"></i></div>
                      Consultas
                    <div class="sb-sidenav-collapse-arrow"><i class="fas fa-angle-down"></i></div>
                  </a>
                  <div class="collapse" id="collapseLayoutscons" aria-labelledby="headingOne" data-parent="#sidenavAccordion">
                      <nav class="sb-sidenav-menu-nested nav">
                        <a  class="nav-link" routerLink="/relatoriofinanceiro"><i class="fas fa-money-bill-alt"></i>&nbsp;Financeiro</a>
                        <a  class="nav-link" routerLink="/relatorioexercicios/conferenciaexercicios"><i class="fas fa-dumbbell"></i>&nbsp;Exercícios</a>
                        <a  class="nav-link" routerLink="/frequencia/conferencia"><i class="fas fa-user-check"></i>&nbsp;Frequência</a>
                        <a  class="nav-link" routerLink="/frequencia/dia"><i class="fas fa-user-check"></i>&nbsp;Frequência por dia</a>
                        <a  class="nav-link" routerLink="/recibopagamento/recibo"><i class="fas fa-clipboard-check"></i>&nbsp;Recibo</a>
                      </nav>
                    </div>
                  <!--Fim do menu-->
                  <div class="sb-sidenav-menu-heading">Complemento</div>
                  <a type="button" class="nav-link" data-toggle="modal" data-target="#ModalLicenca">
                    <div class="sb-nav-link-icon"><i class="fas fa-certificate"></i></div>
                      Sobre
                  </a>
                  <a class="nav-link" href="index.html" (click)="exit()">
                      <div class="sb-nav-link-icon"><i class="fas fa-sign-out-alt"></i></div>
                      Sair
                  </a>
              </div>
          </div>
          <div class="sb-sidenav-footer">
              <div class="small">Usuário: {{ user }}</div>
              <div class="small">Tipo: {{ tipo }}</div>
              {{ nomeEmpresa }}
          </div>
      </nav>
  </div>
<div id="layoutSidenav_content">
  <main class="mt-3">
    <div class="container-fluid">
      <h5 class="text-center"><span style="color:red; font-weight: bold;">ENER</span><span style="color: black; font-weight: bold;">GY</span></h5>
      <h6 class="text-center">Sua academia online</h6>
      <div class="mt-3">
        <router-outlet></router-outlet>
       <div class="d-flex justify-content-center mt-3">
          <span >
            <div class="text-center" *ngIf="espera">
              <p>Aguarde um momento, estamos conctando a Base de Dados...</p>
              <div class="spinner-border text-success" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          </span>
        </div>
      </div>
    </div>
  </main>
  <footer class="py-4">
      <div class="container-fluid">
          <div class="d-flex align-items-center justify-content-end small">
              <div>
                <div class="text-muted">Desenvolvido com <i style="color: red;" class="fas fa-heart"></i> por:&nbsp;<span style="color: red; font-weight: bold;">KEN</span><span style="color:black; font-weight: bold;">TEC&nbsp;2021</span><br/></div>
              </div>
          </div>
      </div>
  </footer>
</div>
</div>
<!-- Modal -->
<div class="modal fade" id="ModalLicenca" tabindex="-1" role="dialog" aria-labelledby="TituloModalCentralizado" aria-hidden="true">
<div class="modal-dialog modal-dialog-centered" role="document">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title" id="TituloModalCentralizado">
      </h5>
    </div>
    <div class="modal-body" style="font-size: 10pt; text-align: center;">
      <div class="text-center mb-1">
        <img src="/assets/kentec.jpg" style="width: 110px; height:100px; border-radius: 10px;">
      </div>
      <h5 style="font-weight: bold; text-align: center;">Termo de uso do sistema.</h5>
      <span style="color: red; font-weight: bold;">&lt;KEN</span><span style="color:black; font-weight: bold;">TEC&#47;&gt;</span><br/>
        <span>Soluções em Informática</span><br/>
        <span >CNPJ: 34.062.698/0001-91</span><br/>
        <span>Sistema: ENERGY - Automação de Academias</span><br/>
        <span>{{ licenca }}</span><br/>
        <span>Versão: 8.52</span><br/>
        <!--(kentec-segundosistema-nomedosistema-nomedaempresa-primeiracopia)HEX-->
        <span style="font-weight: bold; text-align: center; color: blue;">{{ nomeEmpresa }}</span>
    </div>
    <div class="modal-footer">
    </div>
  </div>
</div>
</div>
<!--Fim da modal-->

